import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSwimmer,
  faTableTennis,
  faUtensils,
  faWifi,
  faShirt,
  faHelicopter,
  faCamera,
  faConciergeBell
} from "@fortawesome/free-solid-svg-icons";
import {Fade } from "react-awesome-reveal";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import room1 from "../images/room1.jpg";
import room2 from "../images/room2.jpg";
import room3 from "../images/room3.jpg";
import room4 from "../images/room4.jpg";
import room5 from "../images/room5.jpg";
import room6 from "../images/room6.jpg";
import activity1 from "../images/activity1.jpg";
import activity2 from "../images/activity2.jpg";
import activity3 from "../images/activity3.jpg";
import activity4 from "../images/activity4.jpg";
import activityBackground from "../images/court.jpg";
import promotionalVid from "../videos/yolovid.mp4";
import Footer from "../components/footer";
import { Link, useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minCheckoutDateState,setMinCheckoutDateState] = useState(null);
  useEffect(()=>{
    window.scrollTo(0, 0)
    minCheckoutDate()
  },[])
  const initReservation = () => {
    if (startDate === null || endDate === null) {
      cogoToast.error("Select a check-in and check-out date", {
        position: "bottom-right",
      });
    }
    let checkInDate = startDate;
    let checkoutDate = endDate;
    if (checkInDate !== null && checkoutDate !== null) {
      navigate("/reservations", {
        state: { startDate: checkInDate, endDate: checkoutDate },
      });
    }
  };
  const minCheckoutDate = () => {
    let date = new Date()
    let minCheckoutDateVal = date.setDate(date.getDate() + 1)
    console.log(minCheckoutDateVal)
    setMinCheckoutDateState(minCheckoutDateVal)
    //setEndDate(minCheckoutDateVal)
  }

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 4 || day === 5 || day === 6 || day === 0 ; // 4 for Thursday and 5 for Friday
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="landing-page-image">
              <NavBar />
              <div className="landing-page-inner">
                <div className="landing-page-text">
                  <p className="landing-page-inner-heading1">
                    <Fade triggerOnce={true} duration={"2000"}>
                      Welcome to Yolo Island
                    </Fade>
                  </p>
                  <Fade triggerOnce={true} duration={"5000"}>
                    <p className="landing-page-inner-heading2">
                      Our beautiful and luxurious beach resort <br />
                      at Ibeshe Island, Lagos.
                    </p>
                  </Fade>
                </div>
                <button onClick={() => navigate("/reservations")}>
                  Book Now
                </button> 
                {/* <br/>
                <button onClick={() => navigate("/reservations")}>
                  Membership
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div style={{ padding: "20px" }}>
              <div className="landing-booking-div">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="landing-booking">
                      <h5>Check-in Date</h5>
                      <DatePicker
                        selected={startDate}
                        className="form-control"
                        onChange={(date) => setStartDate(date)}
                        minDate={Date.now()}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeFormat="HH:mm"
                        showTimeSelect
                        filterDate={isWeekend}
                        timeIntervals={15}
                        timeCaption="time"
                        placeholderText="Select check-in date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="landing-booking">
                      <h5>Check-out Date</h5>
                      <DatePicker
                        selected={endDate}
                        timeFormat="HH:mm"
                        className="form-control"
                        showTimeSelect
                        filterDate={isWeekend}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={minCheckoutDateState}
                        placeholderText="Select check-out date"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                  <div className="landing-booking">
                    <h5>Guests</h5>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Select Guests</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div> */}
                  <div className="col-lg-4">
                    <div className="landing-booking">
                      <button
                        className="book-button"
                        style={{ marginTop: "63px" }}
                        onClick={initReservation}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div style={{ width: "100%", margin: "auto" }}>
          <br />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-div">
              <h4>Yolo Island</h4>
              <div>
                <p>
                Nested on a private island amidst the serene clear blue beach water of the Atlantic Ocean is where you will find YOLO Island; the best all-inclusive beach resort in Nigeria.
                </p>
                <p>
                At this luxurious Island, just off the coast of Lagos, you can retreat to a world of divine relaxation, bespoke hospitality and enchanting elegance through features, facilities and services like luxury chalets, boat cruises, restaurant and bar, swimming pool and water sports, recreational activities and many more.
                </p>
                <p>
                We have built YOLO Island to be where you feel your best and most taken care of. Make that decision today and visit our luxurious beach island. We are located at Ibeshe Island, Lagos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-none d-sm-none d-md-none d-lg-block">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="about-us-images0"></div>
                  <div className="about-us-images1"></div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="about-us-images2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <h4>Our Luxury Chalets</h4>
          <hr />
          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>CANCUN</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>IBIZA</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room3})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>SANTORINI</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room4})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>BORA BORA</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room5})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>MADAGASCAR</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="chalet-boxes"
              style={{
                backgroundImage: `url(${room6})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="inner-chalet-boxes">
                <p>FIJI</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div
        style={{
          width: "100%",
          "min-height": "300px",
          backgroundColor: "white",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <br />
              <br />
              <br />
              <h4>Available Facilities</h4>
              <div className="row">
                <div className="col-md-3">
                  <div className="services-div">
                    <Fade triggerOnce={true} duration={"5000"}>
                      <FontAwesomeIcon icon={faSwimmer} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Swimming Pool </h3></p>
                    <p className="services-text">
                    
                    </p>
                    <hr />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="services-div">
                    <Fade
                      triggerOnce={true} duration={"5000"}
                    >
                      <FontAwesomeIcon icon={faWifi} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>High Speed Internet</h3></p>
                    <p className="services-text">
                    
                    </p>
                    <hr />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="services-div">
                    <Fade
                      triggerOnce={true} duration={"5000"}
                    >
                      <FontAwesomeIcon icon={faTableTennis} />
                    </Fade>

                    <hr />
                    <p className="services-heading"><h3>Sporting Activities</h3></p>
                    <p className="services-text">
                 
                    </p>
                    <hr />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="services-div">
                    <Fade
                     triggerOnce={true} duration={"5000"}
                    >
                      <FontAwesomeIcon icon={faCamera} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Cinema</h3></p>
                    <p className="services-text">
                    
                    </p>
                    <hr />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="services-div">
                    <Fade triggerOnce={true} duration={"5000"}>
                      <FontAwesomeIcon icon={faConciergeBell} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Concierge service</h3></p>
                    <p className="services-text">
                    
                    </p>
                    <hr />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="services-div">
                    <Fade triggerOnce={true} duration={"5000"}>
                      <FontAwesomeIcon icon={faHelicopter} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Helipad</h3></p>
                    <p className="services-text">
                
                    </p>
                    <hr />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="services-div">
                    <Fade triggerOnce={true} duration={"5000"}>
                      <FontAwesomeIcon icon={faShirt} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Laundry Services</h3></p>
                    <p className="services-text">
                   
                    </p>
                    <hr />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="services-div">
                    <Fade triggerOnce={true} duration={"5000"}>
                      <FontAwesomeIcon icon={faUtensils} />
                    </Fade>
                    <hr />
                    <p className="services-heading"><h3>Private Dining</h3></p>
                    <p className="services-text">
               
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          "min-height": "500px",
          backgroundColor: "#f3f3f3",
          padding: "40px",
          backgroundImage: `url(${activityBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
        }}
      >
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="activity-side-div">
                <h5>Recreational Activities</h5>
                <br />
                <p>
                The recreational activities offered by YOLO Island provide guests with a variety of options to keep them entertained during their stay at the resort.
                <br /> <br />
                  We have activities such as:
                  <ul>
                    <li>Mini Golf</li>
                    <li>Badminton</li>
                    <li>Volleyball</li>
                    <li>Beach Soccer</li>
                    <li>ATV quad bikes</li>
                    <li>Badminton</li>
                  </ul>
                  <br/>
                  <button
                    className="small-button"
                    onClick={() => navigate("/activities")}
                  >
                    Learn More
                  </button>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="activity-sections"
                    style={{
                      backgroundImage: `url(${activity1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div className="col-md-6">
                  <div
                    className="activity-sections"
                    style={{
                      backgroundImage: `url(${activity2})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="activity-sections"
                    style={{
                      backgroundImage: `url(${activity3})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div className="col-md-6">
                  <div
                    className="activity-sections"
                    style={{
                      backgroundImage: `url(${activity4})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12"></div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="promotional-video-div">
              <div className="container"></div>
              <div className="row">
                <div className="col-md-12">
                  <div className="promotional-video-div-inner">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="promotional-vid-text">
                          <h2 style={{"color":"white"}}>Have a glimpse of the island</h2>
                          <p>
                          Nested on a private island amidst serene clear blue beach water of the Atlantic Ocean is where you will find The Best All-Inclusive Beach Resort in Nigeria.
                          </p>
                          <p>
                          Cocooned among acres of blue water beachfront tropical gardens , fringed by a pristine mile-long beach, YOLO Island immerses you in the splendor of a secluded hideaway
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="promotional-video">
                          <video width="100%" height="540" controls>
                            <source src={promotionalVid} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/> <br />
      </div>
      <br />
      <br />
      {/* <div className="container-fluid">
        <div className="row"> */}
      {/* <div className="col-md-12"> */}
      <Footer />
      {/* </div> */}
      {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default LandingPage;
