import { useEffect, useRef, useState } from "react"
import NavBar from "../components/NavBar"
import { getRequest, postRequest } from "../services/requests"
import cogoToast from "cogo-toast"
import { imageUrl } from "../services/requests"
import { usePaystackPayment } from "react-paystack";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from "../components/footer"

const MenuPage = () =>{
    const [categories,setCategories] = useState(null)
    const [menu,setMenu] = useState(null)
    const [selectedCategory,setSelectedCategory] = useState(1)
    const [activeItem,setActiveItem] = useState(null)
    const [quantity,setQuantity] = useState(0)
    const [mode,setMode] = useState(null)
    const [price,setPrice] = useState(0)
    const [location, setLocation] = useState(null);
    const [confirmationDetails,setConfirmationDetails] = useState(null)
    const orderRef = useRef(null)
    const orderLoader = useRef(null)
    
    const MySwal = withReactContent(Swal)
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
    })
    const [selectedOrder,setSelectedOrder] = useState([])
    
    useEffect(() =>{
        fetchMenu()
    },[])

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    useEffect(() =>{
        if(activeItem !== null && mode === 1){
          if(selectedOrder.some(item => item.id == activeItem.id)){
            selectedOrder.forEach((item,index) =>{
                if(item.id == activeItem.id){
                    let newArr = [...selectedOrder]
                    newArr[index].quantity = quantity
                    setSelectedOrder(newArr)
                    setPrice(parseInt(price) + parseInt(item.price))
                }
            })
          }else{
            if(quantity > 0){
                setSelectedOrder((prevState) =>[...prevState,{
                    'id':activeItem.id,
                    'title':activeItem.title,
                    'price':activeItem.price,
                    'quantity':quantity
                }])
                setPrice(parseInt(price) + parseInt(activeItem.price))
            }
          } 
        }else if(mode === 0){
            if(quantity > 0){
                if(selectedOrder.some(item => item.id == activeItem.id)){
                    selectedOrder.forEach((item,index) =>{
                        if(item.id == activeItem.id){
                            let newArr = [...selectedOrder]
                            newArr[index].quantity = quantity
                            setSelectedOrder(newArr)
                            setPrice(parseInt(price) - parseInt(item.price))
                        }
                    })
                  }
            }else if(quantity < 1){
                setPrice(parseInt(price) - parseInt(activeItem.price))
                const final = selectedOrder.filter(function (order) {
                    return order.id !== activeItem.id;
            });
            setSelectedOrder(final)
            }
        }
        
    },[quantity])

    useEffect(()=>{
        console.log(selectedOrder)
    },[selectedOrder])

    const modalDetails = (e) =>{
        setQuantity(0)
        setMode(null)
       let id =  e.target.id
       menu.forEach(element => {
            if(element.id == id){
                setActiveItem(element)
            }
       });
       selectedOrder.map((item)=>{
            if(item.id == id){
                setQuantity(item.quantity)
            }
       })
    }
    const fetchMenu = async () =>{
          
        try {
            let fetchedCategories =  getRequest('fetch-categories-customer')
            let fetchedMenu = getRequest('fetch-menu-customer')
            let response = await Promise.all([fetchedCategories,fetchedMenu])
            if(response[0].data.status === true){
                setCategories(response[0].data.data)
            }else{
                cogoToast.error(response[0].data.message)
            }
            if(response[1].data.status === true){
                setMenu(response[1].data.data)
            }else{
                cogoToast.error(response[0].data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
    }
    const displayCategory = (e) =>{
        const category = e.target.value
        console.log(categories)
        setSelectedCategory(category)
    }
   
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });

    const increase = () =>{
        setQuantity(quantity + 1)
        setMode(1)

    }
    const decrease = () =>{
        if(quantity > 0){
            setQuantity(quantity - 1)
            setMode(0)
        }
    }
    const showLocation = (position) =>{
        var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            cogoToast.success("Latitude : " + latitude + " Longitude: " + longitude);
    } 
    const locationError = (err) =>{
        if(err.code == 1) {
            cogoToast.error("Location Service Denied Access")
         } else if( err.code == 2) {
            cogoToast.error('Position is unavailable')
         }
    }
    const postOrder = () =>{
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showLocation,locationError,{
    //     enableHighAccuracy: true
    //   });
    // } else {
    //   console.log("Geolocation not supported");
    // }
  }
  const placeOrder = async (e) =>{
        e.preventDefault()
        let address = e.target.address.value.trim()
        let phoneNumber = e.target.phoneNumber.value.trim()
        let emailAddress = e.target.emailAddress.value.trim()
        let confirmEmail = e.target.confirmEmail.value.trim()
        if(!address){
            cogoToast.error('Please provide your delivery address')
        }else if(!phoneNumber){
            cogoToast.error('Please provide your phone number')
        }else if(!emailAddress){
            cogoToast.error('Please provide your email address')
        }else if(!confirmEmail){
            cogoToast.error('please provide email confirmation')
        }else if(emailAddress !== confirmEmail){
            cogoToast.error('Email addresses do not match.')
        }else{
            orderRef.current.style.display = 'none'
            orderLoader.current.style.display = 'block'
            try {
              let response = await postRequest('place-food-order',{"orderDetails":selectedOrder,"phoneNumber":phoneNumber,'address':address,'emailAddress':emailAddress})  
              orderRef.current.style.display = 'block'
            orderLoader.current.style.display = 'none'
              if(response.status == false){
                cogoToast.error(response.message)
              }else{
                 setPaymentConfig(paymentConfig =>({
                            ...paymentConfig,
                            ...{'email': response.data.paymentDetails.email,
                            'amount': response.data.paymentDetails.price,'reference':response.data.paymentDetails.paymentRef   
                            }
                        }))
                        setConfirmationDetails({
                            "email":response.data.paymentDetails.email,
                            "amount":response.data.paymentDetails.price,
                            'reference':response.data.paymentDetails.paymentRef,
                            'paymentId':response.data.paymentDetails.paymentId
                        })
              }
            } catch (error) {
                orderRef.current.style.display = 'block'
            orderLoader.current.style.display = 'none'
                cogoToast.error(error.response.data.message)
            }
        }
    }
    const initializePayment = usePaystackPayment(paymentConfig);
    const onClose = () => {
       console.log("closed");
    }
    const onSuccess = (reference) =>{
        orderRef.current.style.display = 'none'
            orderLoader.current.style.display = 'block'
        postRequest('confirm-food-order-payment',{'reference':reference,'details':confirmationDetails}).then((response)=>{
            orderRef.current.style.display = 'block'
            orderLoader.current.style.display = 'none'
            if(response.data.status === true){
                Swal.fire({
                    icon: 'success',
                    title: 'Order Complete',
                    text: 'Your order is being prepared. You will be notified when it is ready.',
                  }).then((response)=>{
                    //   if(response.isConfirmed){
                    //     //navigate("/")
                    //   }
                  })
            }else{
                cogoToast.error(response.message)
            }
        }).catch((error) =>{
            orderRef.current.style.display = 'block'
            orderLoader.current.style.display = 'none'
        })
    }
    return(
        <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">
            <NavBar/>
          </div>
            </div>
            <div className="row">
                   <div className="col-md-1">
            </div>
            <div className="col-md-10">
            <div className="booking-div">
                    <div className="row g-2">
                        <div className="col-md-8">
                        <h4>Our Menu</h4>
                    <br/>
                                <select onChange={displayCategory} className="form-select">
                                    <option>Select Category</option>
                                {
                                categories !== null ? categories.map((item)=>{
                                    return <option key={item.id} id={item.id} value={item.id}>{item.category}</option>
                                }) : ''
                            }
                                </select>
                    <br/>
            <div className="row">
            {
                    menu !== null && selectedCategory !== null ? menu.map((item)=>{
                      return (item.category == selectedCategory) ?
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6" key={item.id}>
                                <div className="menu-box"  id={item.id}>
                                <div className="row">
                                    <div className="col-md-6">
                                    <div className="menu-image" style={{"padding":"5px"}}>
                                <img src={`${imageUrl+item.image}`} alt='menu-pic' />
                            </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div style={{"padding":"5px"}}>
                                        <span><b>{item.title}</b></span>
                                        <br/>
                                        {item.description}
                                        <br/>
                                <span className="menu-price"><b>{Naira.format(item.price)}</b></span><br/><span className='add-to-order' onClick={modalDetails} data-bs-toggle="modal" data-bs-target="#exampleModal" id={item.id}>Add to order</span>
                                        </div>
                                    </div>
                                </div> 
                                </div> 
                        </div>  : ''                      
                        }) : ''
            }
            </div>

                        </div>
                        <div className="col-md-4">
                        <h4 className="order-summary d-none d-sm-none d-md-block">Order Summary</h4>
                            <div className="order-summary d-none d-sm-none d-md-block">
                                {
                                    selectedOrder !== null ? selectedOrder.map((item)=>{
                                        return <div className="order-summary-items">
                                           <h5>{item.title} {item.quantity}x</h5>  
                                        </div>
                                    }) : ''
                                }
                                <hr/>
                                <h5>Total: {Naira.format(price)}</h5>
                                <hr/>
                                {selectedOrder.length > 0 ?
                                <form onSubmit={placeOrder}>
                                    <input type="text" placeholder="Enter Your Delivery Address" name="address" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Enter Your Phone Number" name="phoneNumber" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Enter Your Email Address" name="emailAddress" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Confirm your email address" name="confirmEmail" className="form-control"/>
                                    <br/>
                                    <div className='spinner-border' role='status' ref={orderLoader} style={{"display":"none"}}></div>
                                 <button className="book-button" style={{'marginLeft':'0'}} ref={orderRef} onClick={postOrder}>Proceed</button>    
                                </form>
                                : ''}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="col-md-1">

                </div>
            </div>

            <br/><br/>
            <Footer/>

            {/* Bootstrap modal starts here */}
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{activeItem !== null ? activeItem.title : null}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <div>
                        {activeItem !== null ? <div>
                            <span className="menu-price"><b>{Naira.format(activeItem.price)}</b></span><br/><br/>
                            <p>{activeItem.description}</p>
                            <small>Select Quantity</small><br/>
                            <span className="quantity-menu"><i class="bi bi-plus-square" onClick={increase}></i></span><span style={{'fontSize':'35px'}}> {quantity} </span><span className="quantity-menu"><i class="bi bi-dash-square" onClick={decrease}></i></span>
                        </div>:null}
                    </div>
                    </div>
                    </div>
                </div>
                </div>
            {/* Bootstrap modal ends here */}

            {/* Small screen order summary starts here  */}
                            <div className="small-screen-summary d-block d-sm-none">
                                
                                <p data-bs-toggle="modal" data-bs-target="#cartModal">
                                <i class="bi bi-bag"></i> View Cart
                                </p>
                            </div>

                            <div className="modal fade" id="cartModal" tabindex="-1" aria-labelledby="cartModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="cartModalLabel">Order Summary</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    {/* <div className="order-summary"> */}
                                {
                                    selectedOrder !== null ? selectedOrder.map((item)=>{
                                        return <div className="order-summary-items">
                                           <h5>{item.title} {item.quantity}x</h5> 
                                        </div>
                                    }) : ''
                                }
                                <hr/>
                                <h5>Total: {Naira.format(price)}</h5>
                                <hr/>
                                {selectedOrder.length > 0 ?
                                <form onSubmit={placeOrder}>
                                    <input type="text" placeholder="Enter Your Delivery Address" name="address" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Enter Your Phone Number" name="phoneNumber" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Enter Your Email Address" name="emailAddress" className="form-control"/>
                                    <br/>
                                    <input type="text" placeholder="Confirm your email address" name="confirmEmail" className="form-control"/>
                                    <br/>
                                    <div className='spinner-border' role='status' ref={orderLoader} style={{"display":"none"}}></div>
                                 <button className="book-button" style={{'marginLeft':'0'}} ref={orderRef} onClick={postOrder}>Proceed</button>    
                                </form>
                                : ''}
                                
                            </div>
                    </div>
                    {/* </div> */}
                </div>
                </div>
            {/* small screen order summary stops here */}
        </div>
    )
}

export default MenuPage