import React, { useEffect, useState, useRef } from "react"
import NavBar from "../components/NavBar"
import cogoToast from "cogo-toast"
import { getRequest, postRequest } from "../services/requests"
import Footer from "../components/footer"
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment/moment';
import OwlCarousel from "react-owl-carousel";
import withReactContent from 'sweetalert2-react-content'
import event1 from "../images/event1.jpg";
import event2 from "../images/event2.jpg";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
export const CurrentEvent = () =>{

    const MySwal = withReactContent(Swal)
    const [userDetails,setUserDetails] = useState(null)
    const [liveEvents,setLiveEvents] = useState(null)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const bookLoader = useRef(null)
    const [selectedEvent,setSelectedEvent] = useState(null)
    const bookRef = useRef(null)
    const [quantity,setQuantity] = useState(1)
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
    })
    useEffect(() =>{
        fetchEvents()
    },[])
    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])
    const initializePayment = usePaystackPayment(paymentConfig);
    const handleClose = () =>{
        setShow(false)
    }
    const handleShow = (e) => {
        setShow(true);
    } 
    const increase = () =>{
        setQuantity(quantity + 1)
    }
    const decrease = () =>{
        if(quantity > 1){
            setQuantity(quantity - 1)
        }
    }
    const fetchEvents = async () =>{
        try {
            let fetchedPackages = await getRequest('fetch-live-events')
            if(fetchedPackages.data.status){
                setLiveEvents(fetchedPackages.data.events)
            }else{
                cogoToast.error(fetchedPackages.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
     
    }
    const makeBooking = async (e) =>{
        e.preventDefault()
        let firstName = e.target.firstName.value
        let lastName = e.target.lastName.value
        let emailAddress = e.target.emailAddress.value
        let emailConfirmation = e.target.emailAddressConfirm.value
        let phoneNumber = e.target.phoneNumber.value
        if(!firstName){
            cogoToast.error("Input your firstname")
        }else if(!lastName){
            cogoToast.error("Input your lastname")
        }else if(!phoneNumber){
            cogoToast.error("Input your phone number")
        }else if(!emailAddress){
            cogoToast.error("Input your email address")
        }else if(!emailConfirmation){
            cogoToast.error("Input email address again to confirm")
        }else if(emailAddress !== emailConfirmation){
            cogoToast.error("Email Addresses do not match")           
        }else{
            setUserDetails({
                "firstName":firstName,
                "lastName":lastName,
                "emailAddress":emailAddress,
                "phoneNumber":phoneNumber,
                'selectedpackage':selectedEvent.id,
                'quantity':quantity
            })
            let data = {
                "firstName":firstName,
                "lastName":lastName,
                "emailAddress":emailAddress,
                "phoneNumber":phoneNumber,
                'selectedpackage':selectedEvent.id,
                'quantity':quantity
            }
            let finalPrice = 0
            liveEvents.forEach(element => {
                if(element.id == selectedEvent.id){
                    let price = parseFloat(element.price) * parseFloat(quantity)
                    finalPrice = price
                }
            });
            MySwal.fire({
                title: <p></p>,
                html:`<h4>You're about to make a payment with the following details.</h4><br/><b>Event Name:</b> ${selectedEvent !== null ? selectedEvent.eventName : ''}</span><br/><br/><span><b>Event Date: </b>${selectedEvent !== null ? moment(selectedEvent.eventDate).format("DD/MM/YYYY") : ''}</span><br/><br/><span><b>Email Address: </b>${emailAddress}</span><br/><br/><b>Total: </b>NGN ${selectedEvent !== null ? Naira.format(finalPrice) : ''}`,
                showCancelButton: true,
                confirmButtonColor: '#1d4028',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Pay!',
                didOpen: () => {
                    
                },
              }).then( async (response)=>{
                if(response.isConfirmed){
                    try {
                        bookRef.current.style.display = "none"
                bookLoader.current.style.display = 'block'
                        let initPayment = await postRequest('init-payment-current-event',data)
                        if(initPayment){
                            if(initPayment.data.status === true){
                                setPaymentConfig(paymentConfig =>({
                                    ...paymentConfig,
                                    ...{'email': emailAddress,
                                    'amount': initPayment.data.amount,'reference':initPayment.data.paymentRef   
                                    }
                                }))
                            }else{
                                cogoToast.error("An error has occured")
                            }
                        }
                    } catch (error) {
                        cogoToast.error(error.response.data.message)
                    }
                }
              })
           
        }
    }
    const onSuccess = (reference) =>{
        
        postRequest('finalize-payment-current-event',{
            "reference":reference,"details":userDetails
          }).then((response) =>{
            if(response.data.status === true){
                bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'none'
                Swal.fire({
                    icon: 'success',
                    title: 'Registration Complete',
                    text: 'Your registration is successfull, please check your email address for more details.',
                  }).then((response)=>{
                      if(response.isConfirmed){
                        navigate("/")
                      }
                  })
            }else{
                bookLoader.current.style.display = "none"
                bookRef.current.style.display = 'block'
                cogoToast.error(response.data.message)
            }
          }).catch((error)=>{
            bookLoader.current.style.display = "none"
            bookRef.current.style.display = 'block'
                cogoToast.error(error.response.data.message)
          })
    }
   const showEventDetails = (e) =>{
    let eventId = e.target.value
    liveEvents.forEach(event => {
        if(event.id == eventId){
            setSelectedEvent(event)
            console.log(event)
        }
    });
   }

   const onClose = () => {
    console.log("closed");
}
   let Naira = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });
    return (
        <React.Fragment>
          
               <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">
            <NavBar/>
          </div>
            </div>
            <div id="visit-main-div">
            <br/><br/>
                <div className="row">
                <OwlCarousel className="owl-theme" loop margin={10} nav responsive={{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        }}>
        
                  <div className="item">
                    <div className="images-event-scroll">
                      <img src={event1} alt="banner" className="event-page-images"/>
                      
                    </div>
                  </div>
                  <div className="item">
                    <div className="images-event-scroll">
                      <img src={event2} alt="banner" className="event-page-images"/>
               
                    </div>
                  </div>
                </OwlCarousel>
                </div>
                <div className="row">
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-6">
                        
                        <div style={{"marginTop":"100px","padding":"20px","backgroundColor":"white","minHeight":"200px","width":"100%"}}>
                        <h1>BUY EVENT TICKETS</h1>
                        <div style={{"marginTop":"20px"}}>
                            <p>We have several packages lined up for you this easter, click on the dropdown and go through our list of events and packages.</p>
                            <select className="form-select" onChange={showEventDetails}>
                                <option>Select Event</option>
                                {
                                    liveEvents !== null ? liveEvents.map((item)=>{
                                        return <option value={item.id} key={item.id}>{item.eventName}</option>
                                    }) : ''
                                }
                            </select>
                        </div>
                        <br/><br/>
                            {selectedEvent !== null ? 
                             <div>
                                <h1>{selectedEvent.eventName}</h1>
                                <hr/>
                                <p>{selectedEvent.eventDescription}</p>
                                <h5>EVENT START DATE: {moment(selectedEvent.startDate).format("DD/MM/YYYY")}</h5>
                                <br/>
                                {selectedEvent.endDate !== null ? <div><h5>EVENT END DATE: {moment(selectedEvent.endDate).format("DD/MM/YYYY")}</h5>
                                <br/></div>: ''}
                             
                                <h5>EVENT TICKET PRICE: {Naira.format(selectedEvent.price)} </h5>
                                <br/>
                                <button className="small-button" onClick={handleShow}>Register</button>
                            </div>
                        : ''}
                            
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={makeBooking}>
                        <input placeholder="Firstname" name="firstName" className="form-control"/><br/>
                        <input placeholder="Lastname" name="lastName" className="form-control"/><br/>
                        <input placeholder="Email Address" name="emailAddress" className="form-control"/><br/>
                        <input placeholder="Confirm Email Address" name="emailAddressConfirm" className="form-control"/><br/>
                        <input placeholder="Phone Number" name="phoneNumber" className="form-control"/><br/>
                        <small>Quantity</small><br/>
                        <i style={{'cursor':'pointer','fontSize':'25px'}} className="bi bi-dash-square" onClick={decrease}></i><span style={{'cursor':'pointer','fontSize':'25px'}}> {quantity} </span> <i className="bi bi-plus-square" style={{'cursor':'pointer','fontSize':'25px'}} onClick={increase}></i> 
                        <br/><br/>
                    <button className='small-button' ref={bookRef}>Proceed</button>
                    <div className='spinner-border' role='status' ref={bookLoader} style={{"display":"none"}}></div>
       </form>
        </Modal.Body>
        </Modal>
            <Footer/>
            </div>
        </React.Fragment>
    )
}

